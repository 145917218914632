import React from "react"
import { Card, Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Settings from "../../common/Settings"

const VerificarAntecedentesCardView = ({ records, isLoading }) => {
  const { t } = useTranslation("global")
  return (
    <Row>
      {records &&
        records.map((item: any, index: number) => {
          return (
            <Col xs={12} md={4} lg={4} key={index}>
              <Card className="mb-4">
                <Card.Body className="p-0 ">
                  {item.imagen && (
                    <img
                      alt=""
                      className="card-img-top"
                      // style={{ maxHeight: "175px" }}
                      id="image"
                      src={"data:image/png;base64," + item.imagen}
                    />
                  )}
                  <p className="text-center my-2 card-text text-uppercase">
                    {`${item.nombres} ${item.apellido1} ${item.apellido2}`}
                    <br />
                    <b>{item.cedula}</b>
                  </p>
                </Card.Body>
                <Card.Footer>
                  {item.fichas > 1 && (
                    <span
                      className="badge bg-danger"
                      title={
                        "Solicitar certificado de no antecedentes judiciales"
                      }
                    >
                      <i className="fa fa-exclamation-triangle"></i>
                    </span>
                  )}
                  {item.fichas == 1 && (
                    <span
                      className="badge bg-warning"
                      title={
                        "Solicitar certificado de no antecedentes judiciales"
                      }
                    >
                      <i className="fa fa-exclamation-triangle"></i>
                    </span>
                  )}
                </Card.Footer>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}

export default VerificarAntecedentesCardView
