/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCog,
  faSignOutAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons"
import {
  faUserCircle,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons"
import { Nav, Navbar, Dropdown, Container } from "react-bootstrap"

//Translation
import { useTranslation } from "react-i18next"

import { Routes } from "../../routes"
import GetCurrentUser from "../../common/GetCurrentUser"
import { userAvatar } from "../../common/helpers"
import { parseJwt } from "../../common/helpers"

// import ChatSidebar from "../Chat/ChatSidebar"
// import ChatNotificationSound from "../../assets/sound/ChatNotification.mp3"
// import SmallMailComposer from "../Mailer/SmallMailComposer"
import { Redirect, useHistory } from "react-router-dom"
import ClearUser from "../../common/ClearUser"

export default props => {
  const [user, setUser] = useState(null)
  const [currentOrganization, setCurrentOrganization] = useState(null)

  const history = useHistory()
  const { t } = useTranslation("global")
  useEffect(() => {
    let theUser = parseJwt(GetCurrentUser())
    setUser(theUser)

    let currntOrg = localStorage.getItem("organization")
    if (currntOrg) {
      setCurrentOrganization(JSON.parse(currntOrg))
    }
  }, [])

  return (
    <>
      <Navbar variant="dark" expanded className="px-2 d-none d-md-block">
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <span
                className="display-5 clickable"
                onClick={() => {
                  history.push(Routes.SelectOrganization.path)
                }}
              >
                {currentOrganization?.name}
                <i className="fas fa-chevron-down ms-2"></i>
              </span>
            </div>
            <Nav className="align-items-center">
              <button
                className="btn me-2"
                onClick={() => {
                  props.history.push(Routes.Settings.clean)
                }}
              >
                <i className="fas fa-cog" />
              </button>

              <button
                className="btn me-2 btn-warning mx-2"
                onClick={() => {
                  props.history.push(Routes.Admin.clean)
                }}
              >
                <i className="fas fa-thunderstorm" />
              </button>

              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <img
                      src={userAvatar(user && user.Email, 40)}
                      className="user-avatar rounded"
                      alt="User"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => {
                      window.location = Routes.Settings.path
                    }}
                  >
                    <FontAwesomeIcon icon={faCog} className="me-2" />{" "}
                    {t("configuration")}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold d-none">
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" />{" "}
                    {t("messages")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="fw-bold"
                    as="button"
                    onClick={() => {
                      window.open("mailto:info@valegos.com", "_blank")
                    }}
                  >
                    <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                    {t("support")}
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item
                    className="fw-bold"
                    onClick={() => {
                      ClearUser()
                      history.push(Routes.Login.path)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />{" "}
                    {t("logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  )
}
