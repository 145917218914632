import React, { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"
import EmployeDTO from "../Employees/EmployeDTO"
import CRUDField from "../core/genericCRUD/CRUDField"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import * as yup from "yup"
import yupTypeValidator from "../../common/YupTypeValidator"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { toast } from "react-toastify"

import Filter from "../core/Filter/Filter"
import VerificarAntecedentesFilterFields from "./VerificarAntecedentes.filterFields"
import Settings from "../../common/Settings"
import moment from "moment"
import ActionBar from "../core/ActionBar"
import VerificarAntecedentesListView from "./VerificarAntecedentesListView"
import VerificarAntecedentesCardView from "./VerificarAntecedentesCardView"
import Pagination from "../core/Pagination"
import { set } from "lodash"

const VerificarAntecedentes = props => {
  // PROPERTIES
  const [viewMode, setViewMode] = useState<"list" | "grid">("list")
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [record, setRecord] = useState<any>(null)
  const [records, setRecords] = useState<any>([])
  const [filterValues, setFilterValues] = useState<any>({})

  const filterFields = VerificarAntecedentesFilterFields
  const getFilterValues = obj => {
    setFilterValues(obj)
  }
  const fields: CRUDField[] = EmployeDTO
  const { t } = useTranslation("global")

  const [isLoading, setIsLoading] = useState(false)
  const user = parsedUser()

  // METHODS

  const onPageChanged = (obj: number) => {
    setPage(obj)
  }
  const bindDataRemote = async () => {
    try {
      setIsLoading(true)
      let query = ""

      if (filterValues.cedula) {
        query += "&id=" + filterValues.cedula
      }
      if (filterValues.firstName) {
        query += "&firstName=" + filterValues.firstName
      }
      if (filterValues.lastName) {
        query += "&lastName=" + filterValues.lastName
      }
      if (filterValues.secondLastName) {
        query += "&lastName2=" + filterValues.secondLastName
      }
      if (filterValues.alias) {
        query += "&alias=" + filterValues.alias
      }

      if (Object.keys(filterValues).length === 0) {
        return
      }

      query += "&page=" + page
      setIsLoading(true)
      let request = await API.getActionExternal(
        Settings.BasePathForAPIPADRON + "cedulados/detalle?" + query
      )
      console.log(request)
      if (request.data.status === "ok") {
        setRecords(request.data.response)
        setTotalPages(request.data.quantityOfPages)
        // setPage(request.data.page)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }
  // EVENTS
  useEffect(() => {
    bindDataRemote()
  }, [page])
  useEffect(() => {
    if (filterValues) {
      setPage(0)
      setTimeout(() => {
        bindDataRemote()
      }, 500)
    }
  }, [filterValues])

  return (
    <Container>
      <ActionBar
        title={t("Verificar y Recomendación de Postulantes")}
        actions={
          <>
            <div className="ms-2 btn-group">
              <button
                onClick={() => setViewMode("grid")}
                className={
                  "btn btn-sm " +
                  (viewMode === "grid" ? "btn-primary" : "btn-secondary")
                }
              >
                Tarjeta
              </button>
              <button
                onClick={() => setViewMode("list")}
                className={
                  "btn btn-sm " +
                  (viewMode === "list" ? "btn-primary" : "btn-secondary")
                }
              >
                Lista
              </button>
            </div>
          </>
        }
      ></ActionBar>

      <div className="my-4">
        <Filter
          // toggleFilter={toggleFilter}
          title={t("Verificar Por:")}
          fields={filterFields}
          setFilterFields={getFilterValues}
          filterAction={() => {
            console.log("filter")

            bindDataRemote()
          }}
        />
      </div>
      {isLoading && (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {viewMode === "list" ? (
        <VerificarAntecedentesListView
          records={records}
          isLoading={isLoading}
        />
      ) : viewMode === "grid" ? (
        <VerificarAntecedentesCardView
          records={records}
          isLoading={isLoading}
        />
      ) : null}
      <Card className="mb-4">
        <Card.Body className="p-0 pt-2">
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={onPageChanged}
          />
        </Card.Body>
      </Card>
    </Container>
  )
}

export default VerificarAntecedentes
