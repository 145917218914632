//We are settings an initial state here for clarity, probably is not needed

const Settings = {
  App: "data-worker",
  Name: "data-worker",

  baseURl: "./",
  BasePathForAPI: "https://api.data-worker.com/api/",
  BasePathForAPIPADRON: "https://api.padron.valegos.com/api/",
  BasePath: "https://api.data-worker.com/",
  AppURL: "https://data-worker.com/",
  // BasePathForAPI: "https://localhost:44322/api/",
  // BasePath: "https://localhost:44322/",

  SocketPath: "",
  SyncFusionKey: ""
}

export default Settings


