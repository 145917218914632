export default [
	{
		name: "name",
		htmlType: "text",
		dbType: "string",
		title: "Name",
		containerClass: "col-12 col-md-4",
		required: false,
	},
	{
		name: "lastName",
		htmlType: "text",
		dbType: "string",
		title: "Last Name",
		containerClass: "col-12 col-md-4",
		required: false,
	},
	{
		name: "alias",
		htmlType: "text",
		dbType: "string",
		title: "Alias",
		containerClass: "col-12 col-md-4",
		required: false,
	},
];