import React from "react"
import { Card, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Settings from "../../common/Settings"
import moment from "moment"

const VerificarAntecedentesListView = ({
  records,

  isLoading,
}) => {
  const { t } = useTranslation("global")
  return (
    <Col xs={12}>
      <Card className="mb-4">
        <Card.Body className="p-0 ">
          <div className="table-responsive">
            {records && records.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th></th>

                    <th>{t("Nombre")}</th>
                    <th className="text-center">{t("Recomendaciones")}</th>
                  </tr>
                </thead>
                <tbody>
                  {records &&
                    records.map((record: any, index: number) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              {record.imagen && (
                                <img
                                  src={"data:image/png;base64," + record.imagen}
                                  style={{
                                    minHeight: "100px",
                                    minWidth: "100px",
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                    objectFit: "cover",
                                    height: "100px",
                                    width: "100px",
                                  }}
                                  className="img-fluid img-thumbnail my-2"
                                  alt=""
                                />
                              )}
                            </td>

                            <td>
                              <b className="me-2">{t("Name")}:</b>
                              {`${record.nombres} ${record.apellido1} ${record.apellido2}`}
                              <br />
                              <b className="me-2">Cédula</b>
                              {record.cedula}
                              <br />
                              <b className="me-2">{t("Fecha de Nacimiento")}</b>
                              {record.fechaNacimiento &&
                                moment(
                                  record.fechaNacimiento.substring(0, 10)
                                ).format("DD/MM/YYYY")}
                              <br />
                              <b className="me-2">{t("Nacionalidad")}</b>
                              {record.nacionalidad}
                            </td>

                            <td className="text-center">
                              {record.fichas > 1 && (
                                <span
                                  className="badge bg-danger"
                                  title={
                                    "Solicitar certificado de no antecedentes judiciales"
                                  }
                                >
                                  <i className="fa fa-exclamation-triangle"></i>
                                </span>
                              )}
                              {record.fichas == 1 && (
                                <span
                                  className="badge bg-warning"
                                  title={
                                    "Solicitar certificado de no antecedentes judiciales"
                                  }
                                >
                                  <i className="fa fa-exclamation-triangle"></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        </>
                      )
                    })}

                  {records.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {t("No Results")}
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-2">
                <h3>{t("No records found")}</h3>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default VerificarAntecedentesListView
