import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { useTranslation } from "react-i18next"

import { Col, Row } from "react-bootstrap"

import Settings from "../../common/Settings"

const EmployeePhotoTypes = (props: any) => {
  // PROPERTIES
  const [photoTypes, setphotoTypes] = useState([])
  const [records, setRecords] = useState<any>(props.records ?? [])
  const { t } = useTranslation("global")
  // METHODS
  const bindDataTypes = async () => {
    try {
      if (props.records) {
        return
      }

      let requestAPI: any = await API.postAction("PhotoType/list", {
        page: 0,
        quantity: 50,
      })
      console.log(requestAPI.data, "photo type")
      if (requestAPI.data.status === "ok") {
        setphotoTypes(requestAPI.data.response)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const bindDataRemote = async () => {
    try {
      console.log(props.records)
      if (props.records) {
        setRecords(props.records)
      } else {
        //We need to create it
        let data: any = []
        photoTypes.forEach((item: any) => {
          data.push({
            id: null,
            typeId: item.id,
            typeName: item.name,
            photo: null,
            deleted: null,
          })
        })

        setRecords(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const uploadImg = async (obj: any, index: number) => {
    let file = obj.target.files[0]
    console.log(file)
    // Ensure it's an image
    if (file.type.match(/image.*/)) {
      console.log("An image has been loaded")
      let bodyData = new FormData()
      bodyData.append("path", file)
      const request = await API.postAction(`File/Upload`, bodyData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      let newRecords = records.map((item: any, internalIndex: number) => {
        if (internalIndex === index) {
          item.photo = request.data.response
        }
        return item
      })

      setRecords(newRecords)
      props.updatePhotosArray(newRecords)

      //props.updateField(model.name, request.data.response)
      //formik.setFieldValue(field, request.data.response)
    }
  }

  // EVENTS
  useEffect(() => {
    if (props.parent.photos) {
      console.log("props", props.parent.photos)
      setRecords(props.parent.photos)
      return
    }
    bindDataTypes()
  }, [props.parent])
  useEffect(() => {
    if (!props.parent.id) {
      bindDataRemote()
    }
  }, [photoTypes])

  return (
    <Row>
      {photoTypes &&
        photoTypes.length > 0 &&
        photoTypes.map((photoTypes: any, index: number) => {
          let item = records.find(
            (item: any) => item.typeId === photoTypes.typeId
          )
          console.log(item?.photo)
          return (
            <Col key={index} className="col-6 col-md-3 col-xl-4">
              <label>
                {t(photoTypes.name)}
                <br />
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                name={"identifierPicture_" + index}
                onChange={e => {
                  uploadImg(e, index)
                }}
              ></input>
              {item?.photo && (
                <img
                  alt=""
                  className="img-fluid img-thumbnail my-2"
                  style={{ width: "200px" }}
                  id="image"
                  src={`${Settings.BasePath}/files/${item?.photo}`}
                />
              )}
            </Col>
          )
        })}
    </Row>
  )
}
export default EmployeePhotoTypes
